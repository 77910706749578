import React, { Fragment } from 'react'
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Menu() {
  return (
    <Fragment>

        <div class="top-bar">
          <div class="container">
            <div class="d-flex justify-content-start align-items-center">
            
              <ul class="top-nav">
                <li><Link class="submenu" to="https://www.instagram.com/al.softo/" target='_blank'><i class="fab fa-instagram"></i></Link></li>
                <li><Link class="submenu" to="https://wa.me/923212092848" target='_blank'><i class="fab fa-whatsapp" target='_blank'></i></Link></li>
                <li><Link class="submenu" to="#"><i class="fab fa-twitter" target='_blank'></i></Link></li>
                <li><Link class="submenu" to="https://www.youtube.com/@AlSoftoTechnology" target="_blank"><i class="fab fa-youtube"></i></Link></li>
              </ul>
              
              <div className='ms-2 ms-md-3 submenu text-xs text-slate-100'> <i class="fas fa-headphones-alt"></i> +92 321 2092848 </div>
              {/*
              <Link class="ms-2 ms-md-3 submenu" to="#!">Contact</Link>
              <Link class="ms-2 ms-md-3 submenu" to="#!">Sign In</Link>
              */}
              </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg py-2" id="navbar-top" data-navbar-soft-on-scroll="data-navbar-soft-on-scroll">
          <div className="container">
            <Link className="navbar-brand me-lg-auto cursor-pointer" to="">
              <img className="alsofto" src="assets/img/logos/logo.png" alt="" />
            </Link>
            <button className="navbar-toggler border-0 pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent" data-navbar-collapse="data-navbar-collapse">
              <div className="container d-lg-flex justify-content-lg-end pe-lg-0 w-lg-100">

                  <ul className="navbar-nav mt-2 mt-lg-1 ms-lg-4 ms-xl-8 ms-2xl-9 gap-lg-x1" data-navbar-nav="data-navbar-nav">
                    <li className="nav-item"> <HashLink className="nav-link nav-bar-item px-0" smooth to="#home" title="home">Home</HashLink></li>
                    <li className="nav-item"> <HashLink className="nav-link nav-bar-item px-0" smooth to="#about" title="about">About</HashLink></li>
                    <li className="nav-item"> <HashLink className="nav-link nav-bar-item px-0" smooth to="#products" title="catalog">Catalog</HashLink></li>
                    <li className="nav-item"> <HashLink className="nav-link nav-bar-item px-0" smooth to="#review" title="reviews">Reviews</HashLink></li>
                    <li className="nav-item"> <HashLink className="nav-link nav-bar-item px-0" smooth to="#support" title="support">Support</HashLink></li>
                  </ul>
              </div>
            </div>
          </div>
        </nav>
    </Fragment>
  )
}

export default Menu