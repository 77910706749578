import React, { Fragment } from 'react';
import Product from './Products';
import ScrollToTop from "react-scroll-to-top";

function Index(){
  return (
    <Fragment>
      
      <main className="main bg-white" id="top">
      
        <div className="container" data-bs-target="#navbar-top" data-bs-spy="scroll" tabindex="0">

          {/*--HERO--*/}
          <section className="mb-9 mb-lg-10 mb-xxl-11 text-center text-lg-start mt-9" id="home">
            <div>
              <div className="row g-4 g-lg-6 g-xl-7 mb-6 mb-lg-7 mb-xl-10 align-items-center">
                <div className="col-12 col-lg-6">
                  <img className="img-fluid w-lg-100" src="assets/img/Hero/man_watering.png" alt="" /></div>
                <div className="col-12 col-lg-6">
                  <h1 className="fs-5 fs-md-3 fs-xxl-2 text-black fw-light mb-4">Be a <span className="fw-bold">IT Partner </span> with <span className="text-gradient fw-bold">AL SOFTO</span></h1>
                  <p className="fs-8 fs-md-11 fs-xxl-7 text-primary mb-5 mb-lg-6 mb-xl-7 fw-light">
                  At Al Softo Technology, we specialize in creating bespoke software tailored to your unique business requirements. Our team collaborates closely with clients to understand their needs and design custom applications that enhance efficiency, productivity, and user experience.
                  </p>
                  <button className="btn btn-gradient fs-8 mt-1 d-inline-flex"><span>explore catalog</span><span className="uil uil-arrow-right"></span></button>
                </div>
              </div>
              <div className="row g-4 g-lg-6 g-xl-7 align-items-center">
                <div className="col-12 col-lg-6 order-1 order-lg-0">
                  <p className="fs-8 fs-md-7 fs-xxl-6 text-success fw-bold mb-x1 text-capitalize">we have Idea for Business </p>
                  <h1 className="fs-5 fs-md-3 fs-xxl-2 text-secondary text-capitalize fw-light mb-4">A great <br className="d-none d-md-block d-lg-none" /><span className="fw-bold">Logo </span><br className="d-none d-xl-block d-xxl-none" /><br className="d-sm-none" />builds inspires trust</h1>
                  <p className="fs-8 fs-md-11 fs-xxl-7 text-primary mb-5 mb-lg-6 mb-xl-7 fw-light">
                  Your logo is the face of your business.
                  if you are looking for a creative & inspiration logo for your project
                  </p>
                  <button className="btn btn-gradient fs-8 d-inline-flex"><span>explore catalog</span><span className="uil uil-arrow-right"></span></button>
                </div>
                <div className="col-12 col-lg-6 order-0 order-lg-1"><img className="img-fluid w-lg-100" src="assets/img/Hero/planting.png" alt="" /></div>
              </div>
            </div>
          </section>


          {/*--SUMMARY--*/}
          <section className="mb-9 mb-lg-10 mb-xxl-11">
            <div className="row g-4">
              <div className="col-12 col-lg-4 text-center">
                <img className="mb-3" src="assets/img/icons/Counter_1.png" alt="" />
                <h1 className="text-secondary fs-4 fs-lg-3 fs-xl-2 counter-delivared" data-countup='{"endValue":9124201,"autoIncreasing":true}' >303</h1>
                <p className="text-success fs-7 fs-xl-6 fw-bold mb-0">
                  Projects <br className="d-none d-xl-block d-xxl-none" />Delivered</p>
              </div>
              <div className="col-12 col-lg-4 text-center"><img className="mb-3" src="assets/img/icons/Counter_2.png" alt="" />
                <h1 className="text-secondary fs-4 fs-lg-3 fs-xl-2" data-countup='{"endValue":36487}' >90</h1>
                <p className="text-success fs-7 fs-xl-6 fw-bold mb-0">
                  Happy <br className="d-none d-xl-block d-xxl-none" />customers </p>
              </div>
              <div className="col-12 col-lg-4 text-center"><img className="mb-3" src="assets/img/icons/Counter_3.png" alt="" />
                <h1 className="text-secondary fs-4 fs-lg-3 fs-xl-2" data-countup='{"endValue":400,"suffix":"+"}' > 23</h1>
                <p className="text-success fs-7 fs-xl-6 fw-bold mb-0">
                  Growing <br className="d-none d-xl-block d-xxl-none" />Portfolio </p>
              </div>
            </div>
          </section>
          
          
          {/*--ABOUT--*/}
          <section className="mb-9 mb-lg-10 mb-xxl-11 text-center text-lg-start" id="about">
            <h4 className="mb-x1 fs-8 fs-md-7 fs-xxl-6 text-success fw-bold pt-6 text-capitalize">About us </h4>
            <h1 className="fs-5 fs-lg-3 fs-xl-2 text-secondary text-capitalize fw-light mb-x1">our <span className="fw-bold">Core </span>team<br />and their <span className="fw-bold">philosophy</span></h1>
            <div className="row mb-7 mb-lg-8 mb-xl-9 gap-3">
              <div className="col-12 col-lg">
                <p className="text-black fs-10 fs-md-9 fs-xxl-8 lh-xl mb-0 line-clamp-5"> 
                At Al Softo IT Solution Services, we're dedicated to providing cutting-edge software solutions tailored to meet the unique needs of our clients. With a team of skilled professionals and a commitment to innovation, we strive to deliver top-notch software products and services that drive business growth and efficiency.
                </p>
              </div>
              <div className="col-12 col-lg">
              Our expertise spans across a wide range of industries, including finance, healthcare, education, and e-commerce. Whether you're a startup looking to establish your digital presence or a large corporation aiming to streamline your operations, we're here to help.
              </div>
            </div>
            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-8 mb-lg-10"> 
              <img className="img-fluid badhon" src="assets/img/team/Mufti_Muhammad_Naeem_Khan.png" alt="" />
              <div className="about-card">
                <h1 className="fs-8 fs-md-7 fs-xxl-6 text-success fw-bold">Meet AL SOFTO</h1>
                <p className="fs-10 fs-md-9 fs-xxl-8 text-primary mb-0 line-clamp-5">
                We take pride in our customer-centric approach, working closely with each client to understand their goals and objectives. From custom software development to system integration and support, we offer comprehensive solutions that empower businesses to thrive in today's dynamic market.
...
                </p>
              </div>
            </div>
            <div>
              <p className="text-secondary text-capitalize fw-light mb-0 fs-5 fs-lg-4 text-center mb-7 mb-lg-8">
                meet the <span className="fw-bold">team</span></p>
              <div className="row mb-13 w-lg-75 mb-lg-5 mx-auto gy-2 gy-lg-5 h-100">
                <div className="col-12 col-md-6 col-lg-4 px-3 text-center mb-5 mb-lg-0">
                  <div className="position-relative w-md-100 mx-auto mb-2"><img className="w-100 team-hero-image" src="assets/img/team/team/Mufti_Muhammad_Naeem_Khan.png" alt="" />
                    <div className="social-icons"><a href="#!"> <span className="uil uil-facebook"></span></a><a href="#!"> <span className="uil uil-instagram-alt"></span></a><a href="#!"> <span className="uil uil-linkedin"></span></a></div>
                  </div>
                  <h3 className="fs-9 fs-md-8 text-success fw-bold mb-1">M Naeem Khan</h3>
                  <h5 className="text-black fs-10 fs-md-9 fw-medium text-capitalize mb-3">CEO</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 text-center mb-5 mb-lg-0">
                  <div className="position-relative w-md-100 mx-auto mb-2"><img className="w-100 team-hero-image" src="assets/img/team/team/Irfan_Khan_Durrani.png" alt="" />
                    <div className="social-icons"><a href="#!"> <span className="uil uil-facebook"></span></a><a href="#!"> <span className="uil uil-instagram-alt"></span></a><a href="#!"> <span className="uil uil-linkedin"></span></a></div>
                  </div>
                  <h3 className="fs-9 fs-md-8 text-success fw-bold mb-1">Irfan Khan Durrani</h3>
                  <h5 className="text-black fs-10 fs-md-9 fw-medium text-capitalize mb-3">FullStack Developer</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 text-center mb-5 mb-lg-0">
                  <div className="position-relative w-md-100 mx-auto mb-2"><img className="w-100 team-hero-image" src="assets/img/team/team/Mirza_Umair_Nadeem_Baig.png" alt="" />
                    <div className="social-icons"><a href="#!"> <span className="uil uil-facebook"></span></a><a href="#!"> <span className="uil uil-instagram-alt"></span></a><a href="#!"> <span className="uil uil-linkedin"></span></a></div>
                  </div>
                  <h3 className="fs-9 fs-md-8 text-success fw-bold mb-1">Mirza Umair Baig</h3>
                  <h5 className="text-black fs-10 fs-md-9 fw-medium text-capitalize mb-3">FullStack Developer</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 text-center mb-5 mb-lg-0">
                  <div className="position-relative w-md-100 mx-auto mb-2">
                    <img className="w-100 team-hero-image" src="assets/img/team/team/Muhammad_Saad_Farooq.png" alt="" />
                    <div className="social-icons"><a href="#!"> <span className="uil uil-facebook"></span></a><a href="#!"> <span className="uil uil-instagram-alt"></span></a><a href="#!"> <span className="uil uil-linkedin"></span></a></div>
                  </div>
                  <h3 className="fs-9 fs-md-8 text-success fw-bold mb-1">M Saad Farooq</h3>
                  <h5 className="text-black fs-10 fs-md-9 fw-medium text-capitalize mb-3">Hardware | Network</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 text-center mb-5 mb-lg-0">
                  <div className="position-relative w-md-100 mx-auto mb-2">
                    <img className="w-100 team-hero-image" src="assets/img/team/team/Tahseen_Ullah_Khan.png" alt="" />
                    <div className="social-icons"><a href="#!"> <span className="uil uil-facebook"></span></a><a href="#!"> <span className="uil uil-instagram-alt"></span></a><a href="#!"> <span className="uil uil-linkedin"></span></a></div>
                  </div>
                  <h3 className="fs-9 fs-md-8 text-success fw-bold mb-1">Tahseen Ullah Khan</h3>
                  <h5 className="text-black fs-10 fs-md-9 fw-medium text-capitalize mb-3">Junior Developer</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-3 text-center mb-5 mb-lg-0">
                  <div className="position-relative w-md-100 mx-auto mb-2"><img className="w-100 team-hero-image" src="assets/img/team/team/Ubaid.png" alt="" />
                    <div className="social-icons"><a href="#!"> <span className="uil uil-facebook"></span></a><a href="#!"> <span className="uil uil-instagram-alt"></span></a><a href="#!"> <span className="uil uil-linkedin"></span></a></div>
                  </div>
                  <h3 className="fs-9 fs-md-8 text-success fw-bold mb-1">M Ubaid K</h3>
                  <h5 className="text-black fs-10 fs-md-9 fw-medium text-capitalize mb-3">Media Expert</h5>
                </div>
              </div>
            </div>
          </section>

          <Product/>

          {/*--CUSTOMERS--*/}
          <section className="mb-9 mb-lg-10 mb-xxl-11" id="review">
            <h3 className="mb-3 pt-6 fs-8 fs-md-7 fs-xxl-6 text-success fw-bold text-center text-lg-start text-capitalize">review </h3>
            <h1 className="fs-5 fs-md-3 fs-xxl-2 text-secondary text-capitalize fw-light mb-6 mb-lg-8 text-center text-lg-start">
              Some words from <br />our <span className="fw-bold">Lovely customers</span></h1>
            <div className="row g-4 g-lg-6 g-xl-7 align-items-center">
              <div className="col-12 col-lg-6">
                <div className="review-card">
                  <div><img src="assets/img/review/1.png" alt="" /></div>
                  <div> <img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" />
                    <p className="text-black fs-10 fs-md-9 fs-xxl-8 mb-3 line-clamp-2">
                    "Thank you for successfully completing your task! We hope to work with you again on future projects."
                      </p>
                    <h2 className="text-success fs-9 fs-md-8 fw-bold mb-0">ADC</h2>
                    <p className="text-black fs-10 fs-md-9 mb-0 mb-0">Oct 22, 2015</p>
                  </div>
                </div>
                <div className="review-card">
                  <div><img src="assets/img/review/2.png" alt="" /></div>
                  <div> <img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" />
                    <p className="text-black fs-10 fs-md-9 fs-xxl-8 mb-3 line-clamp-2">
                    "I Strongly recommend. Very prompt and finished the job before the dead line. Two thumbs up! Fully satisfied with the project!"

                      </p>
                    <h2 className="text-success fs-9 fs-md-8 fw-bold mb-0">Broucher Design</h2>
                    <p className="text-black fs-10 fs-md-9 mb-0 mb-0">Oct 29, 2010</p>
                  </div>
                </div>
                <div className="review-card">
                  <div><img src="assets/img/review/3.png" alt="" /></div>
                  <div> <img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" /><img className="me-1 mb-1" src="assets/img/icons/star.png" alt="" />
                    <p className="text-black fs-10 fs-md-9 fs-xxl-8 mb-3 line-clamp-2">
                    "Naeem made several requested changes until the project was perfect. Thanks, Naeem!"
                      </p>
                    <h2 className="text-success fs-9 fs-md-8 fw-bold mb-0">Graphics Work</h2>
                    <p className="text-black fs-10 fs-md-9 mb-0 mb-0">Oct 9, 2010</p>
                  </div>
                </div>

              </div>
              <div className="col-lg-6 d-block text-center">
                <img className="img-fluid" src="assets/img/illustrations/loveletter.png" alt="" />
              </div>
            </div>
          </section>


          {/*--CONTACT-- */}
          <section className="mb-9 mb-lg-10 mb-xxl-11 text-center text-lg-start mt-1" id="support">
            <div className="row g-4 g-lg-6 g-xl-7 pt-6">
              <div className="d-block col-lg-6 text-center">
                <img className="img-fluid" src="assets/img/illustrations/happy_coding.png" alt="" />
              </div>
              <div className="col-12 col-lg-6 d-flex flex-column justify-content-center mt-0">
                <p className="fs-8 fs-md-7 fs-xxl-6 text-success fw-bold my-0 text-capitalize">join us </p>
                <h1 className="fs-5 fs-lg-4 fs-xl-3 text-secondary text-capitalize fw-light mt-3 mb-4">
                  Subscribe to <br />our <span className="fw-bold">Newsletter</span></h1>
                <p className="text-success fs-8 fs-lg-7 text-capitalize beginner-guide my-4">
                  Get Our <span className="fw-bold">Beginners Guide To Coding </span><br className="d-none d-xxl-block" />for free 
                </p>
                <form method="POST" onsubmit="return false;">
                  <div className="input-group position-relative">
                    <input className="form-control email-input" type="email" name="email" placeholder="your email address" />
                    <button className="btn shadow-none submit-button position-absolute end-0" type="submit"><span className="uil uil-arrow-right"></span></button>
                  </div>
                </form>
              </div>
            </div>
          </section>
         

        {/*
        <button className="btn scroll-to-top" data-scroll-top="data-scroll-top"><span className="uil uil-angle-up text-white"></span></button>
        */}
        </div>

      </main>

      <ScrollToTop />
    </Fragment>
  )
}

export default Index; 