import React, { Fragment } from 'react';
import FooterImg from '../../imgs/footer/footer.png';

function Footer () {
  return (
    <Fragment>
        <footer className="Footer" style={{backgroundImage: `url(${FooterImg})`}}>
          <div className="row bg-dark-teal p-3">
          
            <div className='col-6 text-start fw-light'>
              Al Softo - WebSite & Software Development
            </div>
            <div className='col-6 text-end'>
              <a className="links ms-md-4 fw-light " href="#!" title="F.A.Q"> F.A.Q </a>
              <a className="links ms-3 ms-md-4 fw-light" href="#!" title="Cookies Policy"> Cookies Policy</a>
              <a className="links ms-3 ms-md-4 fw-light" href="#!" title="Legal Terms"> Legal Terms </a>
              <a className="links ms-3 ms-md-4 fw-light" href="#!" title="Privacy Policy"> Privacy Policy</a>
            </div>
            
          </div>
        </footer>
    </Fragment>
  )
}

export default Footer





