import { useRef, useEffect } from 'react';
import { register } from 'swiper/element/bundle';

import  bgProductOne from '../imgs/products/Al_Softo_LogoDesign.png';
import  bgProductTwo from '../imgs/products/Al_Softo_Apps.png';
import  bgProductthree from '../imgs/products/Brand_Madrasa_Combodia_Logo.png';
import  bgProductFour from '../imgs/products/Al_Softo_WordPress.png';

register();

const Products = () => {
  const swiperElRef = useRef(null);

  return (

    <section className="mb-9 mb-lg-10 mb-xxl-11 text-center text-md-start" id="products">
      <h3 className="mb-x1 fs-8 fs-md-7 fs-xxl-6 text-success fw-bold pt-6">Our Products </h3>
      <h1 className="fs-5 fs-md-3 fs-xxl-2 text-secondary text-capitalize fw-light mb-13 mb-lg-7"> <span className="fw-bold">Easy </span>to <br className="d-sm-none" />Handle Business</h1>
      
    <swiper-container
      ref={swiperElRef}
      slides-per-view="3"
      navigation="false"
      pagination="false"
    >
      <swiper-slide>
        <div className="product-card swiper-slide">
          <div className="product-card-top" style={{backgroundImage: `url(${bgProductOne})`}}>
            {/*
            <div className="add-section"><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center" href="#!"><span className="uil uil-file-heart me-1 align-middle"></span>add to wishlist </a><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center text-success fw-bold" href="#!"><span className="uil uil-shopping-cart me-1 align-middle"></span>add to cart</a></div>
            */}
            </div>
          <div className="d-flex flex-column gap-x1 p-x1 pb-5 product-card-body">
            <h3 className="text-success fw-semi-bold text-center line-clamp-1 fs-8 fs-md-11 fs-xxl-7">Logo Design</h3>
            <p className="text-dark fs-10 fs-md-9 fs-xl-8 text-capitalize lh-xl mb-0 line-clamp-3">
              This is the Right place if you are looking for a creative logo for your project.
              
            </p>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div className="product-card swiper-slide">
          <div className="product-card-top" style={{backgroundImage: `url(${bgProductTwo})`}}>
            {/*
            <div className="add-section"><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center" href="#!"><span className="uil uil-file-heart me-1 align-middle"></span>add to wishlist </a><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center text-success fw-bold" href="#!"><span className="uil uil-shopping-cart me-1 align-middle"></span>add to cart</a></div>
              */}
          </div>
          <div className="d-flex flex-column gap-x1 p-x1 pb-5 product-card-body">
            <h3 className="text-success fw-semi-bold text-center line-clamp-1 fs-8 fs-md-11 fs-xxl-7">App & Software</h3>
            <p className="text-dark fs-10 fs-md-9 fs-xl-8 text-capitalize lh-xl mb-0 line-clamp-3">
            creating bespoke software tailored to your unique business requirements.
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div className="product-card swiper-slide">
          <div className="product-card-top" style={{backgroundImage: `url(${bgProductthree})`}}>
           {/* 
           <div className="add-section"><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center" href="#!"><span className="uil uil-file-heart me-1 align-middle"></span>add to wishlist </a><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center text-success fw-bold" href="#!"><span className="uil uil-shopping-cart me-1 align-middle"></span>add to cart</a></div>
          */}
           </div>
          <div className="d-flex flex-column gap-x1 p-x1 pb-5 product-card-body">
            <h3 className="text-success fw-semi-bold text-center line-clamp-1 fs-8 fs-md-11 fs-xxl-7">eCommerce</h3>
            <p className="text-dark fs-10 fs-md-9 fs-xl-8 text-capitalize lh-xl mb-0 line-clamp-3">This water-retaining succulent grows colorful, bell-shaped flowers and withstands dry climates and terrible temperature swings.,</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div className="product-card swiper-slide">
            <div className="product-card-top" style={{backgroundImage: `url(${bgProductFour})`}}>
              {/*
              <div className="add-section"><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center" href="#!"><span className="uil uil-file-heart me-1 align-middle"></span>add to wishlist </a><a className="fs-10 fs-md-9 d-flex flex-column flex-xl-row align-items-center text-success fw-bold" href="#!"><span className="uil uil-shopping-cart me-1 align-middle"></span>add to cart</a></div>
              */}
              </div>
            <div className="d-flex flex-column gap-x1 p-x1 pb-5 product-card-body">
              <h3 className="text-success fw-semi-bold text-center line-clamp-1 fs-8 fs-md-11 fs-xxl-7">WordPress</h3>
              <p className="text-dark fs-10 fs-md-9 fs-xl-8 text-capitalize lh-xl mb-0 line-clamp-3">
              a creative & inspiration logo for your project
              </p>
            </div>
          </div>
      </swiper-slide>
    </swiper-container>
          
    </section>

  );
};

export default Products;